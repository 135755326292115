*{
  margin: 0;
  padding: 0;
}
html, body{
  height: 100%;
}
body{
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background: linear-gradient(109.6deg, rgb(36, 45, 57) 11.2%, rgb(16, 37, 60) 51.2%, rgb(0, 0, 0) 98.6%);
  display: flex;
  justify-content: center;
  align-items: center;
}

h1{
  text-align: center;
  color: rgb(230, 239, 247);
  margin-bottom: 1em;
}
.container{
  height: 700px;
  width: 700px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 1em;
}

.card{
  background: radial-gradient(circle, rgba(223,227,242,1) 1%, rgba(167,164,175,0.18299194677871145) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transform: rotateY(180deg);
  animation: 2s hideCard linear;
  transition: transform 0.5s;
}
@keyframes hideCard{
  0%, 70%{
    transform: rotateY(0);
  }
  100%{
    transform: rotateY(180deg);
  }
}
.card img{
  max-width: 80%;
  max-height: 80%;
  transition: transform 0.5s;
  transform: scale(0);
  animation: 2s hideImage linear;
}
@keyframes hideImage{
  0%, 70%{
    transform: scale(1);
  }
  100%{
    transform: scale(0);
  }
}

.card.active{
  transform: rotateY(0);
}
.card.correct{
  background-color: #65e469;
}
.card.wrong{
  background-color: #fd245a;
}
.card.active img{
  transform: scale(1);
}